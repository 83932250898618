import { render, staticRenderFns } from "./round-trip.vue?vue&type=template&id=31e9d249&scoped=true"
import script from "./round-trip.vue?vue&type=script&lang=js"
export * from "./round-trip.vue?vue&type=script&lang=js"
import style0 from "./round-trip.vue?vue&type=style&index=0&id=31e9d249&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e9d249",
  null
  
)

export default component.exports